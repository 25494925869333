.orico-section-title {
	margin-bottom: 60px;
	text-align: center;
	
	@include media-query(991px) {
		margin-bottom: 50px;
	}
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span{
		text-transform: capitalize;
		font-size: 20px;
		color: $text-color;
		font-family: $base-font;
		font-family: $heading-font;
		font-weight: 400;
		text-align: center;
		line-height: 24px;
		text-decoration-line: underline;
		margin-bottom: 10px;
		display: inline-block;

		span{
          color: $theme-primary-color;
		}
	}

	h2 {
		font-size: 38px;
		line-height: 55px;
		margin: 0;
		margin-top: 15px;
		position: relative;
		font-family: $heading-font;
		font-weight: 500;
		margin-top: 0;
		font-weight: 400;
		font-size: 58px;
		line-height: 58px;
		text-align: center;
		text-transform: uppercase;

		&:before{
          position: unset;
		  left: 0;
		  top: 0;
		  width: 10px;
		  height: 100%;
		  background: $theme-primary-color;
		  content: "";
		}

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p{
		font-size: 18px;
	}

}