/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Itim&family=Outfit:wght@300;400;500;600;700;800;900&display=swap');




// fonts
$base-font-size: 15;
$base-font: 'Outfit', sans-serif;
$heading-font: 'Itim', cursive;



// color
$dark-gray: #232323;
$body-color: #687693;
$white: #fff;
$light: #8188a9;
$black: #000;
$small-black: #333;
$cyan: #848892;

$theme-primary-color: #F78914;
$theme-primary-color-s2: #8AC224;
$body-bg-color: #fff;
$section-bg-color: #F7F6F1;
$section-bg-color2: #F3F1EA;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;
