/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    background: url(../../images/ft-bg.jpg) no-repeat center top / cover;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    z-index: 1;

    &:before{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0,0,0, .9);
    }

    .footer-shape{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
    }

    .ft-shape-1{
        position: absolute;
        left: 30px;
        bottom: 50px;
        z-index: 1;

        @media(max-width:1500px){
          opacity: .5;
        }

        @media(max-width:767px){
            display: none;
        }
    }
    .ft-shape-2{
        position: absolute;
        right: 30px;
        top: 50px;
        z-index: 1;

        @media(max-width:1500px){
          opacity: .5;
        }

        @media(max-width:767px){
            display: none;
        }
    }

    

    ul {
        list-style: none;
    }

    p {
        color: #d1d1d1;
    }

    li {
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 80px 0;
        z-index: 11;
        position: relative;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 20px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-size: 24px;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 700;
        }
    }

    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;
            color: #d1d1d1;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .link-widget,
        .social-widget {

            ul {
                li {
                    display: inline-block;
                    padding-top: 0;
                    padding-right: 15px;

                    a {
                        border: 1px solid #d1d1d1;
                        border-radius: 50px;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            border: 1px solid $theme-primary-color;
                        }

                    }
                }
            }
        }
    }

    .wpo-contact-widget {
        .contact-ft {
            margin-top: 20px;

            @media(min-width:991px){
                padding-right: 30px;
            }
    
            ul {
                li {
                    padding-bottom: 15px;
                    position: relative;
                    padding-left: 35px;
                    color: #d1d1d1;
                    font-size: 16px;
    
                    i{
                        position: absolute;
                        left: 0;
                        top: -1px;
                    }
    
                    .fi:before{
                        font-size: 18px;
                        margin-right: 15px;
                        color: $theme-primary-color;
                    }
                }
            }
        }
        
    }

    .social-widget{
        margin-top: 20px;
    }

    .link-widget,
    .social-widget {
        overflow: hidden;

        @media (max-width: 1199px) {}

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    color: #d1d1d1;
                    font-size: 15px;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .link-widget {
            padding-left: 75px;
        }
    }

    .tag-widget {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                a {
                    display: block;
                    padding: 8px 20px;
                    background: rgba(255, 255, 255, .1);
                    font-size: 16px;
                    color: $white;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 5px;
                }
            }
        }
    }

    .social-widgets {
        ul {
            li {
                a {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 10px;

                    &:hover {
                        color: $theme-primary-color;
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .newsletter-widget {
        form {
            margin-top: 25px;
            position: relative;

            input {
                background-color: #343434;
                height: 50px;
                color: #d1d1d1;
                padding: 6px 20px;
                border-radius: 5px;
                border: none;
                box-shadow: none;
            }

            .submit {
                position: absolute;
                right: 0px;
                top: 0;
                background: $theme-primary-color;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;

                button {
                    background: transparent;
                    border: 0;
                    outline: 0;
                    font-size: 20px;
                    color: $theme-primary-color;
                    position: relative;
                    height: 50px;
                    line-height: 50px;
                    width: 50px;

                    i{
                        position: relative;
                        top: -3px;
                    }
                }
            }
        }
    }


    .wpo-lower-footer {
        text-align: center;
        position: relative;

        .row {
            padding: 20px 0;
            position: relative;
            border-top: 1px solid rgba(255, 255, 255, .125);
        }

        .copyright {
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
            display: flex;
            justify-content: left;

            span {
                color: $theme-primary-color;
                padding: 0 10px 0 5px;
            }

            .copyright-icon {
                padding-right: 10px;
                color: $white;
            }

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }

        p{
            font-size: 15px;
            font-size: 0.9375rem;
            font-weight: 400;
        }

        .link {
            margin-bottom: 0;
            display: flex;
            justify-content: right;

            a {
                color: $white;
            }

            span {
                color: $theme-primary-color;
                padding: 0 10px;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }

    .instagram {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }
    }
}