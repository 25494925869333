/*13. modal-section*/

#popup-quickview {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    z-index: 1111;
    transform: translate(-50%, -50%);
    background: #5e5c5c94;
    @media (max-width:991px) {
            overflow-y: auto;
    }

}



.modal-content .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: $theme-primary-color;
    text-align: center;
    font-size: 24px;
    border: none;
    color: #30180d;
    z-index: 9;
    transition: all .4s ease-in-out;
}

.modal-content .btn-close:hover {
    background-color: $light;
    color: $white;
}

.quickview-dialog .modal-product img {
    width: 100%;
}

.item {
    @media (max-width:991px) {
        width: 100%;

    }

}


.product-details {
    .product-single-content {
        @media (max-width:991px) {
            padding: 20px 50px;
        }

        @media (max-width:767px) {
            padding: 30px 0px;
        }

        h5 {
            font-weight: 700;
            font-size: 34px;
            line-height: 36px;
            margin-bottom: 20px;
            font-family: $heading-font;

            @media (max-width:445px) {
                font-size: 22px;
                line-height: 30px;
                margin-bottom: 0;

            }
        }

        h6 {
            font-weight: 700;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 20px;

            @media (max-width:445px) {
                font-size: 20px;
                line-height: 25px;
            }
        }

        .rating {

            display: flex;
            margin-bottom: 30px;

            li {
                margin-right: 5px;
                color: $light;
                list-style: none;
            }

        }

        p {
            color: $text-light-color;
            line-height: 1.8em;
        }

        .pro-single-btn {
            margin-top: 30px;

            .quantity {
                margin-right: -5px;
                top: 0;
                position: relative;
                display: inline-block;

                input {
                    width: 159px;
                    background: transparent;
                    padding: 15px 35px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    border: none;
                    position: relative;
                    border: 1.5px solid $text-color;

                    @media (max-width:445px) {
                        width: 154px;
                        margin-bottom: 10px;
                    }
                }

                .qtybutton {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    height: 40px;
                    width: 40px;
                    text-align: center;
                    line-height: 38px;
                    font-size: 18px;
                    cursor: pointer;
                    font-size: 25px;
                }

                .qtybutton.inc {
                    right: 0px;
                    left: auto;
                }

            }

            .theme-btn {
                padding: 16px 40px;
                border-radius: 0;

                &::after {
                    position: unset;
                }

                @media (max-width:991px) {
                    top: 0;
                }

                @media (max-width:767px) {
                    padding: 17px 40px;
                    top: -1px;
                }
            }

        }

        .social-share {
            margin-top: 30px;
            display: flex;
            align-items: center;

            span {
                font-weight: 400;
                font-family: $heading-font;
                font-size: 15px;
                color: $text-color;
            }

            .socialLinks {
                margin-left: 10px;

                li {
                    display: inline-block;

                    a {
                        display: block;
                        margin-right: 8px;

                        i {
                            height: 28px;
                            width: 28px;
                            line-height: 28px;
                            text-align: center;
                            color: #ffffff;
                            border-radius: 50%;
                            background: $theme-primary-color;
                        }
                    }
                }
            }
        }

    }

}


/*mobal-subscribe*/
.subscribe-dialog {
    max-width: 900px;
    margin: 200px auto;

    @media(max-width:767px) {
        max-width: 500px;
    }

    .modal-body {
        padding: 0;
    }

    .subscribe-box {
        padding: 50px;

        @media(max-width:767px) {
            padding: 30px;
        }

        h2 {
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 600;

            @media(max-width:767px) {
                font-size: 20px;
            }
        }

        p {
            margin-bottom: 0px;

            @media(max-width:767px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        form {
            position: relative;
            margin-bottom: 25px;
        }

        input[type="email"] {
            height: 50px;
            width: 100%;
            border: 1px solid #ddd;
            padding: 0px 120px 0px 20px;
        }

        input[type="submit"] {
            width: 120px;
            height: 50px;
            border: none;
            text-transform: uppercase;
            position: absolute;
            right: 0;
            cursor: pointer;
            top: 24px;
            background: $black;
            color: $white;

            @media(max-width:767px) {
                top: 21px;
            }
        }

        input[type="submit"]:hover {
            background: $black;
            color: $white;
        }
    }
}

.modal-content {
    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background: #ececec;
        text-align: center;
        font-size: 24px;
        border: none;
        color: $dark-gray;
        z-index: 9;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:hover {
            background-color: $theme-primary-color;
            color: $white;
        }

        .fi {
            &:before {
                font-size: 15px;
            }
        }
    }

}


/*mobal-subscribe*/



.quickview-dialog {
    max-width: 1120px;
    margin: 200px auto;

    @media(max-width:991px) {
        max-width: 500px;
        margin: 100px auto;
    }

    @media(max-width:767px) {
        margin: 50px auto;
    }

    .modal-product {
        text-align: center;
        margin: 0 auto;

        img {
            width: 100%;
            max-width: 300px;
        }
    }

    .product-details {
        position: relative;
        z-index: 1;

        .m-shape {
            position: absolute;
            right: 0;
            bottom: 0;
            content: "";
            z-index: -1;
            max-width: 220px;
            opacity: .03;
            transform: rotate(-45deg);
        }
    }

}

.modal {
    z-index: 9999;
}